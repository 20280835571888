import React, { useContext, useState } from "react";
import travel7 from "../../assets/images/travel/7.jpg";
import { accordionData } from "../../data/dataTwo";

import ShopFooter from "../../component/Footer/shopFooter";
import EcommerceNavbar from "../../component/Navbar/ecommerceNavbar";
import { AppContext } from "../../App";
const Faq = () => {
  let [activeIndex, setActiveIndex] = useState(0);

  let toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(0);
    } else {
      setActiveIndex(index);
    }
  };

  const { content } = useContext(AppContext);
  return (
    <>
      <EcommerceNavbar />
      <section className="relative mt-40 mb-40">
        <div className="container relative md:mt-5 mt-5" id="faq">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              {content.faqTitle}
            </h3>
            <p className="text-slate-400 max-w-xl mx-auto">{content.faqDesc}</p>
          </div>

          <div className="relative grid md:grid-cols-12 grid-cols-1 items-center mt-8 gap-[30px]">
            <div className="md:col-span-6">
              <img
                src={travel7}
                className="rounded-md shadow dark:shadow-gray-800"
                alt=""
              />
            </div>

            <div className="md:col-span-6">
              <div id="accordion-collapse">
                {content?.faq?.map((item, index) => (
                  <div
                    key={index}
                    className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4"
                  >
                    <h2 className="text-base font-semibold">
                      <button
                        type="button"
                        onClick={() => toggleAccordion(index)}
                        className={`flex justify-between items-center p-5 w-full font-medium text-start ${
                          activeIndex === index
                            ? "bg-gray-50 dark:bg-slate-800 text-indigo-600"
                            : ""
                        }`}
                      >
                        <span>{item.title}</span>
                        <svg
                          className={`${
                            activeIndex === index ? "rotate-180" : "rotate-270"
                          } size-4 shrink-01`}
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </button>
                    </h2>
                    {activeIndex === index && (
                      <div>
                        <div className="p-5">
                          <p className="text-slate-400 dark:text-gray-400">
                            {item.content}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>{" "}
      <ShopFooter />
    </>
  );
};

export default Faq;
