import React, { useContext } from "react";
import ShopFooter from "../../component/Footer/shopFooter";
import EcommerceNavbar from "../../component/Navbar/ecommerceNavbar";
import WhoWeAre from "../../component/whoWeAre";
import { AppContext } from "../../App";
const About = () => {
  const { content } = useContext(AppContext);
  return (
    <>
      <EcommerceNavbar />
      <section className="relative mt-40 mb-40">
        <div className="grid grid-cols-1 pb-8 text-center">
          <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
            {content?.about?.pageTitle}
          </h3>

          <p className="text-slate-400 max-w-xl mx-auto">
            {content?.about?.pageDesc}
          </p>
        </div>
        <div id="about">
          <WhoWeAre
            desc={content?.about?.desc}
            title={content?.about?.title}
            years={content?.about?.years}
          />
        </div>
      </section>
      <ShopFooter />
    </>
  );
};

export default About;
