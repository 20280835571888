import React from "react";
import ShopFooter from "../../component/Footer/shopFooter";
import EcommerceNavbar from "../../component/Navbar/ecommerceNavbar";
import { marketingContact } from "../../data/dataFour";

const Contact = () => {
  return (
    <>
      <EcommerceNavbar />
      <section className="relative mt-40 mb-40">
        <div className="grid grid-cols-1 pb-8 text-center">
          <h3 className=" md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
            اتصل بنا الان
          </h3>

          <p className="text-slate-400 max-w-xl mx-auto">
            نحن في انتظار استفسارك
          </p>
        </div>

        <div className="container relative md:mt-5 mt-5">
          <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-[30px]">
            {marketingContact.map((item, index) => {
              const Icons = item.icon;
              return (
                <div className="text-center px-6" key={index}>
                  <div className="size-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                    <Icons />
                  </div>

                  <div className="content mt-7" id="contact">
                    <h5 className="text-xl font-semibold">{item.name}</h5>
                    <p className="text-slate-400 mt-3">{item.desc}</p>

                    <div className="mt-5">
                      <a
                        href={item.link ? item.url : "#contact"}
                        className="text-indigo-600 font-medium"
                        target={item.link ? "_blank" : "_self"}
                        rel="noreferrer"
                      >
                        {item.title}
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <ShopFooter />
    </>
  );
};

export default Contact;
