import { BrowserRouter, Route, Routes } from "react-router-dom";
import IndexShop from "./pages/index/indexShop";
import ShopGridTwo from "./pages/ecommerce/shopGridTwo";
import About from "./pages/customPages/About";
import Faq from "./pages/customPages/Faq";
import Contact from "./pages/customPages/Contact";
import axios from "axios";
import { API } from "./data/data";
import { useEffect, useState } from "react";
import logo_light from "./assets/images/dolphin.png";
import { createContext } from "react";

export const AppContext = createContext();
const contentReq = async () => {
  const resp = await axios.get(`${API}/content`);
  return resp.data;
};

export default function App() {
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState({});

  useEffect(() => {
    setLoading(true);
    contentReq().then((pay) => {      
      setContent(pay);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          zIndex: "10000000",
          position: "fixed",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={logo_light} />
      </div>
    );
  }

  return (
    <AppContext.Provider value={{ content }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<IndexShop />} />
          <Route path="/products" element={<ShopGridTwo />} />
          <Route path="/about" element={<About />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </AppContext.Provider>
  );
}
