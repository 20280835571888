import {
  LiaCrosshairsSolid,
  BiLineChart,
  FiAirplay,
  FiFileText,
  GoRocket,
  GoClock,
  LuUsers,
  LuSearch,
  BiUserVoice,
  BiCreditCardAlt,
  FaRegQuestionCircle,
  MdOutlineCollectionsBookmark,
  HiOutlineCog6Tooth,
  RiPresentationFill,
  LiaFileInvoiceDollarSolid,
  RiMoneyCnyBoxLine,
  MdOutlineJoinInner,
  MdOutlineSelectAll,
  AiOutlineFire,
  LiaRulerCombinedSolid,
  TbBuildingSkyscraper,
  TbBuildingMonument,
  LiaMoneyCheckAltSolid,
  LiaUniversitySolid,
  LuPizza,
  FaRegBuilding,
  BiWater,
  LiaDropbox,
  MdCenterFocusWeak,
  AiOutlineDollar,
  BsShieldCheck,
  FiThumbsUp,
  TbKeyboardShow,
  TbAward,
  BsBookmark,
  FaRegStar,
  LuClock,
  FcAbout,
  FcShop,
  FcFaq,
  FcContacts,
} from "../assets/icons/icons";

import { FaArrowsSpin } from "react-icons/fa6";
import s1 from "../assets/images/shop/items/s1.jpg";
import s2 from "../assets/images/shop/items/s2.jpg";
import s3 from "../assets/images/shop/items/s3.jpg";
import s4 from "../assets/images/shop/items/s4.jpg";
import s5 from "../assets/images/shop/items/s5.jpg";
import s6 from "../assets/images/shop/items/s6.jpg";
import s7 from "../assets/images/shop/items/s7.jpg";
import s8 from "../assets/images/shop/items/s8.jpg";
import s9 from "../assets/images/shop/items/s9.jpg";
import s10 from "../assets/images/shop/items/s10.jpg";
import s11 from "../assets/images/shop/items/s11.jpg";
import s12 from "../assets/images/shop/items/s12.jpg";
import s13 from "../assets/images/shop/items/s13.jpg";
import s14 from "../assets/images/shop/items/s14.jpg";
import s15 from "../assets/images/shop/items/s15.jpg";
import s16 from "../assets/images/shop/items/s16.jpg";

import client1 from "../assets/images/client/01.jpg";
import client2 from "../assets/images/client/02.jpg";
import client3 from "../assets/images/client/03.jpg";
import client4 from "../assets/images/client/04.jpg";

import cafe1 from "../assets/images/cafe/coffee-cup/01.jpg";
import cafe2 from "../assets/images/cafe/coffee-cup/02.jpg";
import cafe3 from "../assets/images/cafe/coffee-cup/03.jpg";
import cafe4 from "../assets/images/cafe/coffee-cup/04.jpg";
import cafe5 from "../assets/images/cafe/coffee-cup/05.jpg";
import cafe6 from "../assets/images/cafe/coffee-cup/06.jpg";
import cafe7 from "../assets/images/cafe/coffee-cup/02.jpg";
import cafe8 from "../assets/images/cafe/coffee-cup/03.jpg";
import cafe9 from "../assets/images/cafe/coffee-cup/04.jpg";

import cafeBlog1 from "../assets/images/cafe/b1.jpg";
import cafeBlog2 from "../assets/images/cafe/b2.jpg";
import cafeBlog3 from "../assets/images/cafe/b3.jpg";

import coffee_time from "../assets/images/cafe/icons/coffee-time.svg";
import pot from "../assets/images/cafe/icons/pot.svg";
import coffee_beans from "../assets/images/cafe/icons/coffee-beans.svg";
import coffee from "../assets/images/cafe/icons/coffee.svg";
import drip_glass from "../assets/images/cafe/icons/drip-glass.svg";
import coffee_cup from "../assets/images/cafe/icons/coffee-cup.svg";

import cause01 from "../assets/images/charity/cause01.jpg";
import cause02 from "../assets/images/charity/cause02.jpg";
import cause03 from "../assets/images/charity/cause03.jpg";
import cause04 from "../assets/images/charity/cause04.jpg";
import cause05 from "../assets/images/charity/cause05.jpg";
import cause06 from "../assets/images/charity/cause06.jpg";

import logo1 from "../assets/images/client/9.png";
import logo2 from "../assets/images/client/10.png";
import logo3 from "../assets/images/client/11.png";
import logo4 from "../assets/images/client/12.png";
import logo5 from "../assets/images/client/13.png";
import logo6 from "../assets/images/client/14.png";

import construction1 from "../assets/images/construction/1.jpg";
import construction2 from "../assets/images/construction/2.jpg";
import construction3 from "../assets/images/construction/3.jpg";
import construction4 from "../assets/images/construction/4.jpg";
import construction5 from "../assets/images/construction/5.jpg";
import construction6 from "../assets/images/construction/6.jpg";

import pro1 from "../assets/images/portfolio/pro1.jpg";
import pro2 from "../assets/images/portfolio/pro2.jpg";
import pro3 from "../assets/images/portfolio/pro3.jpg";
import pro4 from "../assets/images/portfolio/pro4.jpg";
import pro5 from "../assets/images/portfolio/pro5.jpg";
import pro6 from "../assets/images/portfolio/pro6.jpg";

import consulting1 from "../assets/images/consulting/1.jpg";
import consulting2 from "../assets/images/consulting/2.jpg";
import consulting3 from "../assets/images/consulting/3.jpg";

import electronics from "../assets/images/shop/categories/electronics.jpg";
import fashion from "../assets/images/shop/categories/fashion.jpg";
import furniture from "../assets/images/shop/categories/furniture.jpg";
import mobile from "../assets/images/shop/categories/mobile.jpg";
import music from "../assets/images/shop/categories/music.jpg";
import sports from "../assets/images/shop/categories/sports.jpg";

import facial_mask from "../assets/images/spa/icons/facial-mask.png";
import foot_massage from "../assets/images/spa/icons/foot-massage.png";
import massage from "../assets/images/spa/icons/massage.png";
import natural_product from "../assets/images/spa/icons/natural-product.png";
import relax from "../assets/images/spa/icons/relax.png";
import sauna from "../assets/images/spa/icons/sauna.png";
import spa_and_relaxation from "../assets/images/spa/icons/spa-and-relaxation.png";
import spa_candles from "../assets/images/spa/icons/spa-candles.png";
import spa from "../assets/images/spa/icons/spa.png";
import treatment from "../assets/images/spa/icons/treatment.png";

import spaB1 from "../assets/images/spa/b1.jpg";
import spaB2 from "../assets/images/spa/b2.jpg";
import spaB3 from "../assets/images/spa/b3.jpg";

import spa1 from "../assets/images/spa/1.jpg";
import spa2 from "../assets/images/spa/2.jpg";
import spa3 from "../assets/images/spa/3.jpg";

import studio1 from "../assets/images/studio/01.jpg";
import studio2 from "../assets/images/studio/02.jpg";
import studio3 from "../assets/images/studio/03.jpg";
import work1 from "../assets/images/studio/work1.jpg";
import work2 from "../assets/images/studio/work2.jpg";
import work3 from "../assets/images/studio/work3.jpg";
import work4 from "../assets/images/studio/work4.jpg";
import work5 from "../assets/images/studio/work5.jpg";
import work6 from "../assets/images/studio/work6.jpg";
import work7 from "../assets/images/studio/work7.jpg";

import travel1 from "../assets/images/travel/1.jpg";
import travel2 from "../assets/images/travel/2.jpg";
import travel3 from "../assets/images/travel/3.jpg";
import travel4 from "../assets/images/travel/4.jpg";
import travel5 from "../assets/images/travel/5.jpg";
import travel6 from "../assets/images/travel/6.jpg";
import travel7 from "../assets/images/travel/7.jpg";
import travel8 from "../assets/images/travel/8.jpg";

import * as Icon from "react-feather";

export const about = [
  {
    icon: FcAbout,
    feature: "من نحن",
    link: "/about",
  },
  {
    icon: FcShop,
    feature: "منتجاتنا",
    link: "/products",
  },
  {
    icon: FcFaq,
    feature: "الاسئلة الشائعة",
    link: "/faq",
  },
  {
    icon: FcContacts,
    feature: "تواصل معنا",
    link: "/contact",
  },
];
export const courseDetail = [
  {
    heading: "Introductions",
    ls1: "Lesson 1",
    ls2: "Lesson 2",
    ls3: "Lesson 3",
    ls4: "Lesson 4",
  },
  {
    heading: "Mastery",
    ls1: "Lesson 5",
    ls2: "Lesson 6",
    ls3: "Lesson 7",
    ls4: "Lesson 8",
  },
  {
    heading: "Final Thoughts",
    ls1: "Lesson 9",
    ls2: "Lesson 10",
    ls3: "Lesson 11",
    ls4: "Lesson 12",
  },
  {
    heading: "Bonus",
    ls1: "Lesson 13",
    ls2: "Lesson 14",
    ls3: "Lesson 15",
    ls4: "Lesson 16",
  },
];

export const shopFeatures = [
  {
    icon: GoRocket,
    title: "Free Shipping",
    desc: "If the distribution of letters and 'words' is random, the reader will not be distracted from making.",
  },
  {
    icon: BiUserVoice,
    title: "24/7 Support",
    desc: "If the distribution of letters and 'words' is random, the reader will not be distracted from making.",
  },
  {
    icon: BiCreditCardAlt,
    title: "Payment Process",
    desc: "If the distribution of letters and 'words' is random, the reader will not be distracted from making.",
  },
];

export const productData = [
  {
    id: 1,
    image: s1,
    name: "Branded T-Shirt",
    amount: "$21.00",
    descAmount: "$16.00",
    tag: "Featured",
    tagBg: "bg-green-600",
  },
  {
    id: 2,
    image: s2,
    name: "Shopping Bag",
    amount: "$21.00",
    descAmount: "$16.00",
  },
  {
    id: 3,
    image: s3,
    name: "Elegent Watch",
    amount: "$21.00",
    descAmount: "$16.00",
  },
  {
    id: 4,
    image: s4,
    name: "Casual Shoes",
    amount: "$21.00",
    descAmount: "$16.00",
    tag: "New",
    tagBg: "bg-orange-600",
  },
  {
    id: 5,
    image: s5,
    name: "Earphones",
    amount: "$21.00",
    descAmount: "$16.00",
  },
  {
    id: 6,
    image: s6,
    name: "Elegent Mug",
    amount: "$21.00",
    descAmount: "$16.00",
  },
  {
    id: 7,
    image: s7,
    name: "Sony Headphones",
    amount: "$21.00",
    descAmount: "$16.00",
    tag: "Sale",
    tagBg: "bg-indigo-600",
  },
  {
    id: 8,
    image: s8,
    name: "Wooden Stools",
    amount: "$21.00",
    descAmount: "$16.00",
  },
  {
    id: 9,
    image: s9,
    name: "Branded T-Shirt",
    amount: "$21.00",
    descAmount: "$16.00",
    tag: "Popular",
    tagBg: "bg-cyan-500",
  },
  {
    id: 10,
    image: s10,
    name: "Shopping Bag",
    amount: "$21.00",
    descAmount: "$16.00",
    tag: "Popular",
    tagBg: "bg-cyan-500",
  },
  {
    id: 11,
    image: s11,
    name: "Sports Shoes",
    amount: "$21.00",
    descAmount: "$16.00",
    tag: "Popular",
    tagBg: "bg-cyan-500",
  },
  {
    id: 12,
    image: s12,
    name: "T-shirt",
    amount: "$21.00",
    descAmount: "$16.00",
    tag: "Popular",
    tagBg: "bg-cyan-500",
  },
  {
    id: 13,
    image: s13,
    name: "Wooden Chair",
    amount: "$21.00",
    descAmount: "$16.00",
  },
  {
    id: 14,
    image: s14,
    name: "Women Block Heels",
    amount: "$21.00",
    descAmount: "$16.00",
  },
  {
    id: 15,
    image: s15,
    name: "Shorts",
    amount: "$21.00",
    descAmount: "$16.00",
  },
  {
    id: 15,
    image: s16,
    name: "T-shirt",
    amount: "$21.00",
    descAmount: "$16.00",
  },
];

export const productCategories = [
  {
    image: electronics,
    title: "Electronics",
  },
  {
    image: fashion,
    title: "Fashion",
  },
  {
    image: furniture,
    title: "Furniture",
  },
  {
    image: mobile,
    title: "Mobile",
  },
  {
    image: music,
    title: "Music",
  },
  {
    image: sports,
    title: "Sports",
  },
];

export const shopGrid = [
  {
    checked: true,
    gender: "Men",
  },
  {
    checked: false,
    gender: "Women",
  },
  {
    checked: false,
    gender: "Kid.s Wear",
  },
  {
    checked: false,
    gender: "Sports",
  },
  {
    checked: false,
    gender: "Jewellery",
  },
  {
    checked: false,
    gender: "Electronics",
  },
];

export const shopItem = [
  {
    class: "",
    image: client1,
    name: "Calvin Carlo",
    time: "6th May 2022 at 01:25 pm",
    reply: "Reply",
    desc: '" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour "',
  },
  {
    class: "mt-8",
    image: client2,
    name: "Calvin Carlo",
    time: "6th May 2022 at 01:25 pm",
    reply: "Reply",
    desc: '" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour "',
  },
  {
    class: "mt-8",
    image: client3,
    name: "Calvin Carlo",
    time: "6th May 2022 at 01:25 pm",
    reply: "Reply",
    desc: '" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour "',
  },
  {
    class: "mt-8",
    image: client4,
    name: "Calvin Carlo",
    time: "6th May 2022 at 01:25 pm",
    reply: "Reply",
    desc: '" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour "',
  },
];

export const helpData = [
  {
    icon: FaRegQuestionCircle,
    title: "FAQs",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
    link: "/helpcenter-faqs",
  },
  {
    icon: MdOutlineCollectionsBookmark,
    title: "Guides / Support",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
    link: "/helpcenter-guides",
  },
  {
    icon: HiOutlineCog6Tooth,
    title: "Support Request",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
    link: "/helpcenter-support",
  },
];

export const priceData = [
  {
    title: "Free",
    desc: "We offers a free month of service for new customers.",
    features: [
      "Full Access",
      "Source Files",
      "Free Appointments",
      "Enhanced Security",
    ],
    plan: "0",
    btn: "Signup",
  },
  {
    title: "Starter",
    desc: "We offers a free month of service for new customers.",
    features: [
      "Full Access",
      "Source Files",
      "Free Appointments",
      "Enhanced Security",
    ],
    plan: "9",
    btn: "Buy Now",
  },
  {
    title: "Business",
    desc: "We offers a free month of service for new customers.",
    features: [
      "Full Access",
      "Source Files",
      "Free Appointments",
      "Enhanced Security",
    ],
    plan: "29",
    btn: "Started now",
  },
];
export const appFeature = [
  {
    icon: RiPresentationFill,
    title: "Design & Branding",
    desc: "The most well-known which is said to have originated",
  },
  {
    icon: LiaFileInvoiceDollarSolid,
    title: "Fully Secured",
    desc: "The most well-known which is said to have originated",
  },
  {
    icon: RiMoneyCnyBoxLine,
    title: "High Performance",
    desc: "The most well-known which is said to have originated",
  },
];

export const businessAbout = [
  {
    icon: Icon.PieChart,
    title: "Profitable Marketing",
    desc: "We develop digital strategies, products and services appreciated by clients.",
  },
  {
    icon: Icon.Briefcase,
    title: "SEO Specialists",
    desc: "We develop digital strategies, products and services appreciated by clients.",
  },
  {
    icon: Icon.Target,
    title: "Audience Analysis",
    desc: "We develop digital strategies, products and services appreciated by clients.",
  },
];
export const priceTwo = [
  {
    title: "STARTER",
    amount: "39",
    subData: [
      "Full Access",
      "Source Files",
      "Free Appointments",
      "Enhanced Security",
    ],
  },
  {
    title: "BUSINESS",
    amount: "49",
    subData: [
      "Full Access",
      "Source Files",
      "Free Appointments",
      "Enhanced Security",
    ],
  },
  {
    title: "PROFESSIONAL",
    amount: "59",
    subData: [
      "Full Access",
      "Source Files",
      "Free Appointments",
      "Enhanced Security",
    ],
  },
];
export const cafeData = [
  {
    image: cafe1,
    name: "Cafe Latte",
    price: "13.79",
  },
  {
    image: cafe2,
    name: "Ice Coffee",
    price: "12.49",
  },
  {
    image: cafe3,
    name: "Mocha",
    price: "10.99",
  },
  {
    image: cafe4,
    name: "Espresso",
    price: "19.99",
  },
  {
    image: cafe5,
    name: "Americano",
    price: "17.79",
  },
  {
    image: cafe6,
    name: "Boerewors",
    price: "57.79",
  },
  {
    image: cafe1,
    name: "Cafe Latte",
    price: "13.79",
  },
  {
    image: cafe7,
    name: "Ice Coffee",
    price: "12.49",
  },
  {
    image: cafe8,
    name: "Mocha",
    price: "10.99",
  },
  {
    image: cafe9,
    name: "Espresso",
    price: "19.99",
  },
];
export const service2 = [
  {
    imageList: cafe1,
  },
  {
    imageList: cafe5,
  },
  {
    imageList: cafe6,
  },
  {
    imageList: cafe5,
  },
  {
    imageList: cafe5,
  },
  {
    imageList: cafe6,
  },
  {
    imageList: cafe1,
  },
  {
    imageList: cafe2,
  },
  {
    imageList: cafe3,
  },
  {
    imageList: cafe4,
  },
  {
    imageList: cafe5,
  },
  {
    imageList: cafe6,
  },
  {
    imageList: cafe5,
  },
  {
    imageList: cafe6,
  },
];
export const cafeBlog = [
  {
    image: cafeBlog1,
    title: "Best Coffee Shops In The State You Should Know",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    image: cafeBlog2,
    title: "Best Coffee Shops In The State You Should Know",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    image: cafeBlog3,
    title: "Best Coffee Shops In The State You Should Know",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
];

export const cafeAbout = [
  {
    image: coffee_time,
    title: "High Quality",
    desc: "Composed in a pseudo-Latin language which more or less pseudo-Latin.",
  },
  {
    image: pot,
    title: "Pure Grades",
    desc: "Composed in a pseudo-Latin language which more or less pseudo-Latin.",
  },
  {
    image: coffee_beans,
    title: "Proper Roasting",
    desc: "Composed in a pseudo-Latin language which more or less pseudo-Latin.",
  },
  {
    image: coffee,
    title: "The Perfect Cup",
    desc: "Composed in a pseudo-Latin language which more or less pseudo-Latin.",
  },
  {
    image: drip_glass,
    title: "Coffee Machine",
    desc: "Composed in a pseudo-Latin language which more or less pseudo-Latin.",
  },
  {
    image: coffee_cup,
    title: "Coffee to Go",
    desc: "Composed in a pseudo-Latin language which more or less pseudo-Latin.",
  },
];
export const cafeImage = [
  cafe1,
  cafe2,
  cafe3,
  cafe4,
  cafe5,
  cafe1,
  cafe2,
  cafe3,
  cafe4,
  cafe5,
  cafe6,
  cafe2,
  cafe3,
];

export const causesData = [
  {
    image: cause01,
    title: "Donate For Food",
    desc: "If you use this site regularly and would like to help keep the site on the Internet",
    Progress: "45%",
  },
  {
    image: cause02,
    title: "Food For Orphan",
    desc: "If you use this site regularly and would like to help keep the site on the Internet",
    Progress: "50%",
  },
  {
    image: cause03,
    title: "Home For Homeless",
    desc: "If you use this site regularly and would like to help keep the site on the Internet",
    Progress: "83%",
  },
  {
    image: cause04,
    title: "Holyday Gifts In Kind",
    desc: "If you use this site regularly and would like to help keep the site on the Internet",
    Progress: "40%",
  },
  {
    image: cause05,
    title: "For clean Water in Africa",
    desc: "If you use this site regularly and would like to help keep the site on the Internet",
    Progress: "55%",
  },
  {
    image: cause06,
    title: "Health and Rights",
    desc: "If you use this site regularly and would like to help keep the site on the Internet",
    Progress: "60%",
  },
];
export const partnerImage = [logo1, logo2, logo3, logo4, logo5, logo6];

export const saasFeatures = [
  {
    icon: MdOutlineJoinInner,
    title: "Grow Your Business",
    desc: "If the distribution of letters and words is random, the reader will not be distracted from making.",
  },
  {
    icon: MdOutlineSelectAll,
    title: "Drive More Sales",
    desc: "If the distribution of letters and words is random, the reader will not be distracted from making.",
  },
  {
    icon: AiOutlineFire,
    title: "Handled By Expert",
    desc: "If the distribution of letters and words is random, the reader will not be distracted from making.",
  },
];
export const constructionProject = [
  {
    image: construction1,
    title: "Serenity Apartments",
    name: "Branding",
  },
  {
    image: construction2,
    title: "The Landscape House",
    name: "Mockup",
  },
  {
    image: construction3,
    title: "New Build Family Home",
    name: "Abstract",
  },
  {
    image: construction4,
    title: "Private and Social Apartments",
    name: "Books",
  },
  {
    image: construction5,
    title: "Apartment Complex",
    name: "V-card",
  },
  {
    image: construction6,
    title: "Construction Engineering",
    name: "Photography",
  },
];
export const constructionAbout = [
  {
    icon: LiaRulerCombinedSolid,
    title: "Project Planning",
    desc: "One disadvantage of Lorum Ipsum is that in Latin frequently than others",
  },
  {
    icon: TbBuildingSkyscraper,
    title: "Qualified Specialists",
    desc: "One disadvantage of Lorum Ipsum is that in Latin frequently than others",
  },
  {
    icon: TbBuildingMonument,
    title: "Constant Maintenance",
    desc: "One disadvantage of Lorum Ipsum is that in Latin frequently than others",
  },
  {
    icon: HiOutlineCog6Tooth,
    title: "Warranty Service",
    desc: "One disadvantage of Lorum Ipsum is that in Latin frequently than others",
  },
];
export const consultingAbout = [
  {
    icon: Icon.Codesandbox,
    title: "User Friendly",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    icon: Icon.Send,
    title: "Super Fast",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    icon: Icon.Star,
    title: "Insightful Analytics",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    icon: Icon.Bookmark,
    title: "Highly Rated",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
];
export const consultingProduct = [
  {
    image: pro1,
    title: "Techwind Personal Portfolio",
    name: "Creative",
  },
  {
    image: pro2,
    title: "Techwind Minimal Portfolio",
    name: "Minimal",
  },
  {
    image: pro3,
    title: "Techwind NFT Market",
    name: "Crypto",
  },
  {
    image: pro4,
    title: "Techwind Portfolio",
    name: "Portfolio",
  },
  {
    image: pro5,
    title: "Techwind Constuction",
    name: "Corporate",
  },
  {
    image: pro6,
    title: "Techwind SEO",
    name: "SEO",
  },
];
export const consultingServices = [
  {
    image: consulting1,
    title: "Profitable Marketing",
    desc: "We develop digital strategies, products and services appreciated by clients.",
  },
  {
    image: consulting2,
    title: "SEO Specialists",
    desc: "We develop digital strategies, products and services appreciated by clients.",
  },
  {
    image: consulting3,
    title: "Audience Analysis",
    desc: "We develop digital strategies, products and services appreciated by clients.",
  },
];
export const corporateAbout = [
  {
    icon: RiPresentationFill,
    title: "Best Financial Advice",
    desc: "The most well-known which is said to have originated",
  },
  {
    icon: LiaFileInvoiceDollarSolid,
    title: "Authorised Finance Brand",
    desc: "The most well-known which is said to have originated",
  },
  {
    icon: LiaMoneyCheckAltSolid,
    title: "Compehensive Advices",
    desc: "The most well-known which is said to have originated",
  },
  {
    icon: RiPresentationFill,
    title: "Best Tax Advantages",
    desc: "The most well-known which is said to have originated",
  },
];
export const corporateAbout2 = [
  {
    icon: Icon.Codesandbox,
    title: "User Friendly",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    icon: Icon.Send,
    title: "Super Fast",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    icon: Icon.Star,
    title: "Insightful Analytics",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    icon: Icon.Bookmark,
    title: "Highly Rated",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
];

export const corporateServices2 = [
  {
    icon: LiaUniversitySolid,
    title: "Banking & Finance",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    icon: LuPizza,
    title: "Food & Commodities",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    icon: FaRegBuilding,
    title: "Real estate",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
];
export const watchAbout = [
  {
    Icon: Icon.Droplet,
    title: "Waterproof",
    desc: "If the distribution of letters and 'words' is random, the reader will not be distracted from making.",
  },
  {
    Icon: Icon.MapPin,
    title: "Built in GPS",
    desc: "If the distribution of letters and 'words' is random, the reader will not be distracted from making.",
  },
  {
    Icon: Icon.Activity,
    title: "Health Tracking",
    desc: "If the distribution of letters and 'words' is random, the reader will not be distracted from making.",
  },
];
export const spaData = [
  {
    image: facial_mask,
    type: "Facial Mask",
  },
  {
    image: foot_massage,
    type: "Foot Massage",
  },
  {
    image: massage,
    type: "Massage",
  },
  {
    image: natural_product,
    type: "Natural Product",
  },
  {
    image: relax,
    type: "Relax",
  },
  {
    image: sauna,
    type: "Sauna",
  },
  {
    image: spa_and_relaxation,
    type: "Spa & Relaxation",
  },
  {
    image: spa_candles,
    type: "Spa Candles",
  },
  {
    image: spa,
    type: "Spa",
  },
  {
    image: treatment,
    type: "Treatments",
  },
];
export const spaBlog = [
  {
    image: spaB1,
    title: "Hiking for health in Peru",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    image: spaB2,
    title: "Sisley Spa opens at Miramar",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    image: spaB3,
    title: "Top 5 Tips for Choosing a Day Spa",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
];

export const spaAabout = [
  {
    image: spa1,
    name: "Spa",
    title: "RELAXATION",
  },
  {
    image: spa2,
    name: "Cosmatic",
    title: "ESSENTIAL BALANCE",
  },
  {
    image: spa3,
    name: "Massage",
    title: "NATURAL HEALTH ",
  },
];
export const startupAbout = [
  {
    icon: BiWater,
    title: "24/7 Support",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    icon: LiaDropbox,
    title: "Take Ownership",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    icon: MdCenterFocusWeak,
    title: "Take Ownership",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
];
export const startupServices = [
  {
    icon: RiPresentationFill,
    title: "Digital Marketing",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    icon: LiaFileInvoiceDollarSolid,
    title: "Investing",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    icon: LiaMoneyCheckAltSolid,
    title: "Mortgage Advisor",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    icon: RiPresentationFill,
    title: "Real Estate",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    icon: AiOutlineDollar,
    title: "Payroll & Accounting",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    icon: LiaFileInvoiceDollarSolid,
    title: "Branch Registration",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
];
export const workPortfolio = [
  {
    image: work1,
    title: "Techwind Personal Portfolio",
    name: "Creative",
  },
  {
    image: work2,
    title: "Techwind Minimal Portfolio",
    name: "Minimal",
  },
  {
    image: work3,
    title: "Cryptor NFT Market",
    name: "Crypto",
  },
  {
    image: work4,
    title: "Techwind Portfolio",
    name: "Portfolio",
  },
  {
    image: work5,
    title: "Techwind Constuction",
    name: "Corporate",
  },
  {
    image: work6,
    title: "Techwind SEO",
    name: "SEO",
  },
  {
    image: work7,
    title: "Cryptor NFT Market",
    name: "Crypto",
  },
];
export const workServices = [studio1, studio2, studio3];
export const studioAbout = [
  {
    icon: RiPresentationFill,
    title: "Digital Marketing",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    icon: LiaFileInvoiceDollarSolid,
    title: "Investing",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    icon: RiMoneyCnyBoxLine,
    title: "Mortgage Advisor",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    icon: RiPresentationFill,
    title: "Real Estate",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    icon: AiOutlineDollar,
    title: "Payroll & Accounting",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    icon: LiaFileInvoiceDollarSolid,
    title: "Branch Registration",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
];
export const travelBenefit = [
  {
    icon: BsShieldCheck,
    title: "Secure Payment",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    icon: FiThumbsUp,
    title: "Easy Book",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    icon: TbKeyboardShow,
    title: "Free Amenities",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    icon: TbAward,
    title: "Best Offers",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    icon: BsBookmark,
    title: "Cheap than Other",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    icon: FaRegStar,
    title: "Top Rated",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    icon: LuClock,
    title: "24/7 Support",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    icon: FaArrowsSpin,
    title: "Fast Refund",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
];
export const destinationData = [
  {
    image: travel1,
    place: "Thailand",
    amount: 59,
    category: "beach",
  },
  {
    image: travel2,
    place: "Bali",
    amount: 49,
    category: "park",
  },
  {
    image: travel3,
    place: "Bangkok",
    amount: 59,
    category: "nature",
  },
  {
    image: travel4,
    place: "Pataya",
    amount: 69,
    category: "mountain",
  },
  {
    image: travel5,
    place: "Europe",
    amount: 49,
    category: "beach",
  },
  {
    image: travel6,
    place: "Maldivas",
    amount: 79,
    category: "park",
  },
  {
    image: travel7,
    place: "Dubai",
    amount: 59,
    category: "nature",
  },
  {
    image: travel8,
    place: "India",
    amount: 59,
    category: "mountain",
  },
];
