import React, { useContext } from "react";
import { Link } from "react-router-dom";

import ShopFooter from "../../component/Footer/shopFooter";
import EcommerceNavbar from "../../component/Navbar/ecommerceNavbar";
import { about } from "../../data/dataTwo";
import { AppContext } from "../../App";

export default function IndexShop() {
  const { content } = useContext(AppContext);

  return (
    <>
      <EcommerceNavbar />

      <section className="relative mt-20 mb-20">
        <div className="container-fluid relative">
          <div className="relative py-24 table w-full shadow-md overflow-hidden">
            <div className="absolute inset-0 bg-[url('../../assets/images/bg2.jpg')] bg-no-repeat md:bg-left bg-center bg-cover"></div>
            <div className="absolute inset-0 bg-slate-950/30"></div>
            <div className="container relative">
              <div className="grid grid-cols-1">
                <div className="md:text-start text-center">
                  <h1 className="font-bold text-white lg:leading-normal leading-normal text-4xl lg:text-5xl">
                    {content?.companyArabic}
                  </h1>
                  <h1 className="font-bold text-white lg:leading-normal leading-normal text-4xl lg:text-5xl mb-6">
                    {content?.companyEnglish}
                  </h1>
                  <p className="text-white/70 text-xl max-w-xl">
                    {content?.homepage?.inTroDescription}
                  </p>
                  <div className="mt-6">
                    <Link
                      to={"/products"}
                      className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md me-2 mt-2"
                    >
                      <i className="mdi mdi-cart-outline"></i>
                      {content?.homepage?.homepageButton}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="container relative">
              {/* <div className="grid grid-cols-1 pb-8 text-center">
                <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-white">
                  تصفح موقعنا
                </h3>
              </div> */}

              <div className="flex justify-center mt-10 gap-x-[30px] gap-y-[50px]">
                {about.map((data, index) => {
                  let Icons = data.icon;
                  return (
                    <Link
                      to={data.link}
                      className="title h5 text-lg font-medium hover:text-indigo-600"
                    >
                      <div className="text-center md:px-3" key={index}>
                        <div className="size-24 bg-white/45 text-indigo-600 rounded-3xl text-4xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                          <Icons className="size-7" />
                        </div>

                        <div className="content mt-7 text-white">{data.feature}</div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              تصفح موقعنا
            </h3>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 mt-4 gap-x-[30px] gap-y-[50px]">
            {about.map((data, index) => {
              let Icons = data.icon;
              return (
                <Link
                  to={data.link}
                  className="title h5 text-lg font-medium hover:text-indigo-600"
                >
                  <div className="text-center md:px-3" key={index}>
                    <div className="size-24 bg-indigo-600/5 text-indigo-600 rounded-3xl text-4xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                      <Icons className="size-7" />
                    </div>

                    <div className="content mt-7">{data.feature}</div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </section> */}

      <h2 className="mb-7 text-4xl" style={{ textAlign: "center" }}>
        {content?.homepage?.footermessage}
      </h2>

      <ShopFooter />
    </>
  );
}
